'use client';
import Script from 'next/script';

export default function LeadpipePixel() {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }
  return (
    <>
      <Script
        id="leadpipe-pixel"
        src={`https://api.leadpipe.com/cs?apiKey=${process.env.NEXT_PUBLIC_LEADPIPE_API_KEY}`}
        strategy="afterInteractive"
      />
    </>
  );
}
